import React, { useEffect } from "react";

export const ImportScript = ({ src, onLoad, async } = { async: true }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = src;
    script.async = async;
    script.onload = onLoad;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [src, async, onLoad]);
  return <div />;
};

import baseTheme from "@theme-ui/preset-base"; // https://theme-ui.com/presets/base
import { merge } from "theme-ui";

// merge will deeply merge custom values with the
// unknown theme's defaults

const colors = {
  ...baseTheme.colors,
  primary: "#489fd9",
  secondary: "#344961",

  "logo-text": "#031b33",
  gray: "#2b2c2e",
  "gray-light": "#939596",
  "gray-dark": "#1c1d1f",
};

export default merge(baseTheme, {
  colors,
  sizes: {
    container: 960,
  },
  borders: {
    default: `1px solid ${colors.muted}`,
  },
});

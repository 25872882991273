import React from "react";
import { MDXProvider } from "@mdx-js/react";
// import { Code } from './src/components/code'
// import { preToCodeBlock } from 'mdx-utils'
import { ImportScript } from "./src/components/import-script";

// components is its own object outside of render so that the references to
// components are stable
// https://github.com/hagnerd/gatsby-starter-blog-mdx
const components = {
  ImportScript,
  // pre: preProps => {
  //   const props = preToCodeBlock(preProps)
  //   // if there's a codeString and some props, we passed the test
  //   if (props) {
  //     return <Code {...props} />
  //   } else {
  //     // it's possible to have a pre without a code in it
  //     return <pre {...preProps} />
  //   }
  // },
};
export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>{element}</MDXProvider>
);

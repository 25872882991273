// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-md": () => import("./../../../src/pages/about.md" /* webpackChunkName: "component---src-pages-about-md" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-desktop-app-js": () => import("./../../../src/pages/desktop-app.js" /* webpackChunkName: "component---src-pages-desktop-app-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-md": () => import("./../../../src/pages/privacy.md" /* webpackChunkName: "component---src-pages-privacy-md" */),
  "component---src-pages-typescript-example-tsx": () => import("./../../../src/pages/typescript-example.tsx" /* webpackChunkName: "component---src-pages-typescript-example-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

